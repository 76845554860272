import { useState, useEffect } from "react"
import styles from "./Courses.module.css"

function Courses() {

    const [courses, setCourses] = useState(true)
    const [awards, setAwards] = useState(false)


    function displayCourses() {
        setCourses(true);
        setAwards(false)
    }


    function displayAwards() {
        setCourses(false);
        setAwards(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className={styles.page}>
            <div className={styles.cont}>
                <section className={styles.btnCont}>
                    <button className={`${styles.btn} ${courses && styles.active} `}
                        onClick={displayCourses}>Courses</button>
                    <button className={`${styles.btn} ${awards && styles.active} `}
                        onClick={displayAwards}>Awards</button>
                </section>

                {courses &&
                    <section className={styles.courseCont}>

                        <div className={styles.row}>
                            <p className={styles.left}>Data Structures & Algorithms</p> <p className={styles.right}>Mission College</p>
                        </div>
                        <hr />
                        <div className={styles.row}>
                            <p className={styles.left}>Structure and Interpretation of Signals and Systems</p> <p className={styles.right}>UC Berkeley</p>
                        </div>
                        <hr />

                        <div className={styles.row}>
                            <p className={styles.left}>Introduction to Microelectronic Circuits</p> <p className={styles.right}>UC Berkeley</p>
                        </div>
                        <hr />

                        <div className={styles.row}>
                            <p className={styles.left}>Discrete Math</p> <p className={styles.right}>UC Berkeley ext</p>
                        </div>
                        <hr />

                        <div className={styles.row}>
                            <p className={styles.left}>Linear Algebra & Differential Equations</p> <p className={styles.right}>Merritt College</p>
                        </div>
                        <hr />

                        <div className={styles.row}>
                            <p className={styles.left}>Calculus I, II, III</p> <p className={styles.right}>Mission College</p>
                        </div>
                        <hr />

                        <div className={styles.row}>
                            <p className={styles.left}>AWS Certified Cloud Practitioner Certification</p> <p className={styles.right}>In Progress</p>
                        </div>
                        <hr />




                    </section>
                }

                {awards && <section className={styles.awardsCont}>


                    <div className={styles.row}>
                        <p className={styles.awardLeft}>Scholarship</p> <p className={styles.awardRight}>UC Berkeley Regents and Chancellor's Scholarship</p>
                    </div>
                    <hr />

                    <div className={styles.row}>
                        <p className={styles.awardLeft}>Scholarship</p> <p className={styles.awardRight}>UCLA Regents and Chancellor's Scholarship</p>
                    </div>
                    <hr />


                    <div className={styles.row}>
                        <p className={styles.awardLeft}>Scholarship/Internship</p> <p className={styles.awardRight}>Cisco Systems Scholarship Program</p>
                    </div>
                    <hr />


                </section>
                }

            </div>
        </div>
    )
}

export default Courses