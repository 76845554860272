import { useState, useEffect } from "react"
import styles from "./Contact.module.css"

// const hostname = "http://localhost:4000/"

function Contact() {
    // const [name, setName] = useState("")

    const [contact, setContact] = useState({ name: "", email: "", phone: "", subject: "", message: "" })

    const { name, email, phone, subject, message } = contact;
    function handleChange(e) {
        const { name, value } = e.target;
        // setName(value);
        setContact(prevData => {
            return {
                ...prevData,
                [name]: value
            }
        })
    }

    function resetForm() {
        setContact({ name: "", email: "", phone: "", subject: "", message: "" })
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (!name || !email || !phone || !subject || !message) {
            alert("Please fill in all missing data");
            return;
        } else {
            // alert("passed first test")
        }



        fetch(`https://quiet-lake-14974.herokuapp.com/contact`, {
            mode: 'cors',
            credentials: 'include',
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            body: JSON.stringify(contact),
        })
            .then((response) => {


                console.log("response: ", response); //should be getting response
                resetForm();

                if (response.status >= 200 && response.status < 300) {
                    //alert("submission zuccessful");
                    return Promise.resolve(response);
                }


            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.page}>
            <div className={styles.cont}>
                <h2>Contact</h2>
                <div className={styles.formWrapper}>
                    <form className={styles.formCont} onSubmit={handleSubmit}>
                        <label htmlFor="name">
                            <input
                                className={styles.nameField}
                                name="name"
                                placeholder="Your Name"
                                value={name}
                                onChange={handleChange}
                            />
                        </label>
                        <div>
                            <input
                                className={styles.emailField}
                                name="email"
                                placeholder="Your Email"
                                value={email}
                                onChange={handleChange}
                            />
                            <input
                                className={styles.phoneField}
                                name="phone"
                                placeholder="Phone"
                                value={phone}
                                onChange={handleChange}
                            />
                        </div>
                        <input
                            className={styles.subjField}
                            name="subject"
                            placeholder="Subject"
                            value={subject}
                            onChange={handleChange}
                        />
                        <textarea
                            className={styles.msgField}
                            name="message"
                            placeholder="Your Message"
                            value={message}
                            onChange={handleChange}

                        />
                        <button className={styles.btn}
                            onClick={handleSubmit}>SEND</button>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default Contact