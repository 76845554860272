import styles from "./About.module.css"

function About() {
    return (
        <div className={styles.page}>

            <div className={styles.cont} >
                <h2>About Me</h2>
                <p className={styles.about}>I'm a software engineer with a primary focus on JavaScript/React on the frontend and
                    Nodejs on the backend. Experienced in building scalable fullstack applications and actively contributing in the open source community.
                    Passionate about building user friendly interfaces as much as implementing backend solutions. Keen on
                    keeping up with new trends and industry standards and producing high quality work.
                    {/* primarily focused on Javascript/React on the frontend and Node/Express
                    on the backend, with experience in both SQL and NoSQL databases. I'm passionate
                    about building fullstack software applications. Enjoy solving both UI problems as well
                    as implementing backend solutions. Adaptable to new technologies and focused on producing
                    high-quality work.  
                    Specialized in creating scaleable applications across a broad web stack (React/Redux/Node.js) and actively contributing in the open source community.

                    */}
                </p>
            </div>
        </div>
    )
}

export default About