import styles from "./Card.module.css"
// import sample from "../videos/sample.mov"
// import project1 from "../images/projects/project1.png"

function Card({ title, desc, pic }) {
    return (
        <div className={styles.card}>
            <div className={styles.text}>

                <h3 className={styles.title}>{title}</h3>
                <h4 className={styles.desc}>{desc} </h4>

                {/* <h5 className={styles.stack}>Tech: React, Context API, MongoDB, Express, JWT</h5> */}
            </div>
            <img className={styles.image} src={pic} alt="project" />
            <button className={styles.quickbtn}>Quick view</button>

            {/* <video width="330" height="230" className={styles.video} controls autoPlay={true} muted playsInline >
                <source src={sample} type="video/mp4" />
                <source src={sample} type="video/ogg" />
            </video> */}
        </div>
    )
}


export default Card