import React from "react"
import { Routes, Route } from "react-router-dom"
import Header from "./components/Header"
// import Home from "./pages/home/Home"
import About from "./pages/about/About"
import Tech from "./pages/tech/Tech"
import Experience from "./pages/experience/Experience"
import Portfolio from "./pages/portfolio/Portfolio"
import Courses from "./pages/courses/Courses"
import Contact from "./pages/contact/Contact"
import "./index.css"


function App() {
  return (
    <div className="app-cont">
      <Header />

      <Routes>
        <Route exact path="/" element={<><About /></>} />
        <Route path="/about" element={<><About /></>} />
        <Route path="/tech" element={<><Tech /></>} />
        <Route path="/experience" element={<><Experience /></>} />
        <Route path="/portfolio" element={<><Portfolio /></>} />
        <Route path="/courses" element={<><Courses /></>} />
        <Route path="/contact" element={<> <Contact /></>} />
      </Routes>
    </div>
  );
}

export default App;
