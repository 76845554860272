import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./Portfolio.module.css"
import Card from "../../components/Card.js"
import pic1 from "../../images/projects/project1-min.png"
import pic2 from "../../images/projects/perfectMatch-min.png"
import pic3 from "../../images/projects/pegg-min.png"
import pic4 from "../../images/projects/gasBud-min.png"
import pic5 from "../../images/projects/table-min.png"
import pic6 from "../../images/projects/googleList-min.png"
import pic7 from "../../images/projects/airbnb-min.png"
import pic8 from "../../images/projects/blog-min.png"
import pic9 from "../../images/projects/assess-min.png"


function Portfolio() {

    const navigate = useNavigate()

    function getPortfolio() {


        fetch(`https://quiet-lake-14974.herokuapp.com/portfolio`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            mode: 'no-cors',
        })
            .then((response) => {


                console.log("response: ", response); //should be getting response


                if (response.status >= 200 && response.status < 300) {
                    //alert("submission zuccessful");
                    navigate("/portfolio")
                    return Promise.resolve(response);
                }


            })
    }
    useEffect(() => {
        // eslint-disable-next-line
        getPortfolio()
        window.scrollTo(0, 0);
    }, []);  // eslint-disable-next-line

    return (
        <div className={styles.page}>

            <div className={styles.cont}>
                <h2>Portfolio</h2>

                <section className={styles.wrapper}>
                    <div className={styles.cardCont}>
                        <Card title="Lingo Talk"
                            pic={pic1}
                            desc="Platform that allows teachers to post their schedule and the languages they teach. Students can book lessons, rate the teachers etc" />
                        <Card
                            title="Perfect Match"
                            pic={pic2}
                            desc="Application that leverages an award winning algorithm to group people based on their preferences."
                        />
                        <Card
                            title="Gas App UI"
                            pic={pic4}
                            desc="UI and layout improvements for a gas app website."
                        />

                        <Card
                            title="Application Tracker"
                            pic={pic6}
                            desc="A simple searchable list compiled for Google apprenticeship applicants."
                        />

                        <Card
                            title="Pegg Tutors"
                            pic={pic3}
                            desc="Tutoring site modeled on the image and functionality of the now defunct Chegg Tutors site."
                        />
                        <Card
                            title="Lodging Info"
                            pic={pic7}
                            desc="Platform similar to airbnb where users can search properties and add their own."
                        />

                        <Card
                            title="Table component"
                            pic={pic5}
                            desc="An npm library that allows developers to search and filter through 1M+ rows of data with minimal latency."
                        />

                        <Card
                            title="Blog"
                            pic={pic8}
                            desc="UI for interactive news and blog section."
                        />

                        <Card
                            title="Pair Programming App"
                            pic={pic9}
                            desc="A platform that allows users to post their level and availability and match with a pair programming partner.."
                        />
                        {/* <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card /> */}
                    </div>

                </section>

            </div>
        </div>
    )

}
export default Portfolio