import React from "react"
import { Link } from "react-router-dom"
import styles from "./Header.module.css"

function Header() {
    return (
        <div className={styles.cont}>
            <Link to="/" className={`${styles.link}`}> <p><i class="fa-solid fa-house"></i></p> </Link>

            <Link to="/about" className={styles.link}> <p><i class="fa-solid fa-user"></i></p> </Link>
            <Link to="/tech" className={styles.link}> <p><i class="fa-solid fa-laptop-code"></i> </p></Link>
            {/* <p>Exper</p> */}
            <Link to="/portfolio" className={styles.link}> <p><i class="fa-regular fa-folder-open"></i></p> </Link>

            <Link to="/courses" className={styles.link}> <p><i class="fa-solid fa-graduation-cap"></i></p> </Link>

            <Link to="/contact" className={styles.link}>   <p><i class="fa-solid fa-envelope"></i></p></Link>



        </div>
    )
}

export default Header