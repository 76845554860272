import React, { useEffect } from "react"
import styles from "./Tech.module.css"
import html from "../../images/html5.png"
import css from "../../images/css.png"
import js from "../../images/js.png"
import react from "../../images/reactjs.png"
import redux from "../../images/redux.png"
import svelte from "../../images/sveltejs.png"
import node from "../../images/nodejs.png"
import sql from "../../images/postgresql.png"
import mongodb from "../../images/mongodb.png"
import aws from "../../images/aws.png"
import chrome from "../../images/chrome.png"
import git from "../../images/git.png"
import jestImg from "../../images/jest.png"
import linux from "../../images/linux.png"
import jquery from "../../images/jquery.png"
import netlify from "../../images/netlify.png"
import auth from "../../images/auth0.png"

function Tech() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.page}>

            <div className={styles.cont} >
                <h2>Technologies I Use</h2>

                <div className={styles.rows}>
                    <div className={`${styles.row} ${styles.row1}`}>
                        <div><img src={js} alt="js logo" /></div>
                        <div><img src={react} alt="react logo" /></div>
                        <div><img src={redux} alt="redux logo" /></div>
                        <div><img src={svelte} alt="svelte logo" /></div>
                        <div><img src={html} alt="html logo" /></div>
                        <div><img src={css} alt="css logo" /></div>
                    </div>
                    <div className={`${styles.row} ${styles.row2}`}>
                        <div><img src={node} alt="nodejs logo" /></div>
                        {/* <div id={styles.nopic}>Express</div> */}
                        <div><img src={linux} alt="linux logo" /></div>
                        <div><img src={sql} alt="postgres logo" /></div>
                        <div><img src={mongodb} alt="mongodb logo" /></div>
                        <div><img src={auth} alt="auth logo" /></div>
                        <div><img src={aws} alt="aws logo" /></div>
                    </div>

                    <div className={`${styles.row} ${styles.row3}`}>
                        <div className={styles.invis}><img src={chrome} alt="chrome logo" /></div>
                        <div><img src={git} alt="git logo" /></div>

                        <div><img src={jestImg} alt="jest logo" /></div>

                        <div><img src={jquery} alt="jquery logo" /></div>
                        <div><img src={netlify} alt="netlify logo" /></div>
                        <div className={styles.invis}><img src={linux} alt="linux logo" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tech